import React, { useEffect, useState } from "react";
import {
  Button,
  CardActions,
  Create,
  Datagrid,
  DateField,
  DateInput,
  List,
  Resource,
  SimpleForm,
  FunctionField,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  Edit,
  NumberInput,
  SimpleList,
} from "react-admin";
import { PerformanceResultsFilterSidebar } from "./ResultsSidebar";
import { Drawer, Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import EditIcon from "@material-ui/icons/Edit";
import QueueIcon from "@material-ui/icons/Queue";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import ListIcon from "@material-ui/icons/List";
import { PerformanceTypeSelectAutocomplete } from "./CustomInputs";
import { globalRefresh } from "../../../helpers/globalRefresh";
import { ResultsGraph } from "./ResultsGraph";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import dayjs from "dayjs";
import { isPlayer } from "../../../helpers/isPlayer";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const RESULTS_VIEWS = {
  list: "list",
  graph: "graph",
};

const ListActions = ({
  basePath,
  match,
  setShowCreate,
  setResultsView,
  setShowSidebar,
  isList,
  isGraph,
}) => (
  <CardActions>
    {isList && (
      <Button
        onClick={() => setResultsView(RESULTS_VIEWS.graph)}
        label="Pokaz wykres"
      >
        <TrendingUpIcon />
      </Button>
    )}
    {isGraph && (
      <Button
        onClick={() => setResultsView(RESULTS_VIEWS.list)}
        label="Pokaz liste"
      >
        <ListIcon />
      </Button>
    )}
    <Button onClick={() => setShowSidebar(true)} label="Pokaz filtry">
      <FilterListIcon />
    </Button>
    {!isPlayer() ? (
      <Button onClick={() => setShowCreate(true)} label="Dodaj wynik">
        <QueueIcon />
      </Button>
    ) : null}
  </CardActions>
);

const PlayerResultsBasicList = (props) => {
  const classes = useStyles();
  const [resultsView, setResultsView] = useState(RESULTS_VIEWS.graph);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [record, setRecord] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const displayDesktop = useMediaQuery("(min-width:1280px)");
  const handleClose = () => {
    setShowCreate(false);
  };

  const handleEditClose = () => {
    setShowEdit(false);
    globalRefresh();
  };

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      setShowEdit(true);
    }
  }, [record]);

  const isGraph = resultsView === RESULTS_VIEWS.graph;
  const isList = resultsView === RESULTS_VIEWS.list;

  const pagination = isGraph
    ? {
        pagination: false,
      }
    : {};

  return (
    <>
      <List
        filter={{ "user.id": props.match.params.id }}
        {...props}
        aside={
          <PerformanceResultsFilterSidebar
            showSidebar={showSidebar}
            onClose={() => {
              setShowSidebar(false);
              globalRefresh();
            }}
          />
        }
        syncWithLocation
        basePath={`/performance-results/${props.match.params.id}`}
        {...pagination}
        actions={
          <ListActions
            {...props}
            setShowCreate={setShowCreate}
            setResultsView={setResultsView}
            isGraph={isGraph}
            isList={isList}
            setShowSidebar={setShowSidebar}
          />
        }
      >
        {isList ? (
          displayDesktop ? (
            <Datagrid
              rowClick={(id, basePath, record) => {
                setRecord(record);
              }}
            >
              <TextField source="performance_type.typeName" label="Ćwiczenie" />
              <TextField source="value" label="Wynik" />
              <TextField source="performance_type.unit" label="Jednostka" />
              <DateField source="date" label="Data" />
              <FunctionField
                render={(record) => {
                  return (
                    <MuiButton color="secondary">
                      <EditIcon />
                    </MuiButton>
                  );
                }}
              />
            </Datagrid>
          ) : (
            <SimpleList
              primaryText={(record) => {
                return record.performance_type.typeName;
              }}
              secondaryText={(record) =>
                `${record.value} ${record.performance_type.unit}`
              }
              tertiaryText={(record) => (
                <MuiButton color="secondary" onClick={() => setRecord(record)}>
                  <EditIcon />
                </MuiButton>
              )}
              // tertiaryText={(record) => dayjs().from(dayjs(record.date))}
              linkType={false}
            />
          )
        ) : (
          <ResultsGraph />
        )}
      </List>
      <Drawer
        open={showCreate}
        anchor="right"
        onClose={() => {
          handleClose();
          globalRefresh();
        }}
      >
        <PlayerResultCreate
          className={classes.drawerContent}
          onCancel={handleClose}
          {...props}
        />
      </Drawer>
      <Drawer
        open={showEdit}
        anchor="right"
        onClose={() => {
          handleEditClose();
          globalRefresh();
        }}
      >
        <PlayerResultEdit
          className={classes.drawerContent}
          onCancel={handleEditClose}
          {...props}
          record={record}
        />
      </Drawer>
    </>
  );
};

const PlayerResultCreate = ({ onCancel, ...props }) => {
  const [unit, setUnit] = useState("");
  const [playerId, setPlayerId] = useState(props.match.params.id);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setPlayerId(props.match.params.id);
  }, [props.match.params.id]);

  const onSuccess = () => {
    notify(`Dodano wynik`);
    onCancel();
    refresh();
  };

  return (
    <Create
      title="Dodaj wynik"
      {...props}
      onSuccess={onSuccess}
      mutationMode="optimistic"
    >
      <SimpleForm>
        <TextInput
          disabled
          name="user"
          label="Zawodnik"
          defaultValue={playerId}
        />
        <PerformanceTypeSelectAutocomplete
          name="performance_type"
          label="Ćwiczenie"
          onSelect={(data) => setUnit(data.unit)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <NumberInput source="value" label="Wartość" />
          <span style={{ marginLeft: 10 }}>{unit}</span>
        </div>
        <DateInput source="date" label="Data" />
      </SimpleForm>
    </Create>
  );
};

const PlayerResultEdit = ({ onCancel, ...props }) => {
  const [unit, setUnit] = useState("");
  const [playerId, setPlayerId] = useState(props.match.params.id);

  useEffect(() => {
    setPlayerId(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <Edit
      title="Edytuj wynik"
      {...props}
      basePath={`/performance-results/${props.match.params.id}/show`}
      id={props.record.id}
      mutationMode="optimistic"
    >
      <SimpleForm>
        <TextInput
          disabled
          name="user"
          label="Zawodnik"
          defaultValue={playerId}
        />
        <PerformanceTypeSelectAutocomplete
          name="performance_type"
          label="Ćwiczenie"
          onSelect={(data) => setUnit(data.unit)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <NumberInput source="value" label="Wartość" />
          <span style={{ marginLeft: 10 }}>{unit}</span>
        </div>
        <DateInput source="date" label="Data" />
      </SimpleForm>
    </Edit>
  );
};

export const PlayerResultsList = (props) => {
  return <Resource name="performance-results" list={PlayerResultsBasicList} />;
};
