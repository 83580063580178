import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PaymentField, TrainingHappenedField } from "./customs/CustomFields";
import { globalRefresh } from "../../helpers/globalRefresh";
import dayjs from "dayjs";
import { isPlayer } from "../../helpers/isPlayer";
import { fetchRequest } from "../../api/fetchRequest";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");

export const TrainingModal = ({
  isOpen,
  setTrainingModalState,
  trainingModalData,
  sections,
}) => {
  const onClose = () => {
    setTrainingModalState(false);
  };
  const { player, date, price, id } = trainingModalData.training || {};
  const { training } = trainingModalData || {};
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Trening z <strong>{`${player?.name} ${player?.surname}`}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <table>
            <tr>
              <td>
                <strong>Data</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                {dayjs(date).format("DD/MM/YYYY HH:mm (dd.)")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sekcja</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                {sections[player?.section]?.name}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Płatność</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                {!isPlayer() && (
                  <span style={{ marginRight: 10 }}>{price} PLN</span>
                )}
                <PaymentField record={training} />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Trening zrealizowano</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                <TrainingHappenedField record={training} />
              </td>
            </tr>
            {training?.notes && (
              <tr>
                <td colspan="2">
                  <strong
                    style={{
                      display: "inline-block",
                      marginTop: 20,
                    }}
                  >
                    Notatki
                  </strong>
                  <br />
                  <p dangerouslySetInnerHTML={{ __html: training?.notes }}></p>
                </td>
              </tr>
            )}
          </table>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!isPlayer() && (
          <Button
            onClick={() => {
              fetchRequest(
                "/trainings/" + id,
                {},
                {
                  method: "DELETE",
                }
              );
              globalRefresh();
              onClose();
              window.location.hash = "/trainings";
            }}
            color="primary"
            style={{
              marginRight: 50,
            }}
          >
            Usuń
          </Button>
        )}
        {!isPlayer() && (
          <Button
            onClick={() => {
              onClose();
              window.location.hash = "/trainings/" + id;
            }}
            color="primary"
          >
            Edytuj
          </Button>
        )}
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
