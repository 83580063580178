// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { DashboardShortcut } from "../DashboardShortcut/DashboardShortcut";
import {
  Users,
  TrendingUp,
  Activity,
  Columns,
  Tool,
  Calendar,
} from "react-feather";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Card>
    <CardHeader title="Dzień dobry!" />
    <CardContent>
      <div class="dashboardGrid">
        <DashboardShortcut icon={<Users />} href="/#/users" label="Zawodnicy" />
        <DashboardShortcut
          icon={<TrendingUp />}
          href="/#/performance-results"
          label="Wyniki"
        />
        <DashboardShortcut
          icon={<Activity />}
          href="/#/trainings"
          label="Treningi"
        />
        <DashboardShortcut
          icon={<Calendar />}
          href="/#/permanent-reservations"
          label="Rezerwacje"
        />
        <DashboardShortcut
          icon={<Columns />}
          href="/#/training-types"
          label="Sekcje"
        />
        <DashboardShortcut
          icon={<Tool />}
          href="/#/performance-types"
          label="Ust. wyników"
        />
      </div>
    </CardContent>
  </Card>
);
