// in src/users.js
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
  Create,
  SelectInput,
  Filter,
  SimpleList,
} from "react-admin";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setTitle } from "../../api/title";
import { PlayerSelectAutocomplete } from "../trainings/customs/CustomInputs";
const AvatarField = ({ source, record = {}, ...rest }) => {
  return (
    <img src={record.user.avatarUrl} height="60" width="60" alt="user avatar" />
  );
};

const UserListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Szukaj wg nazwiska" source="surname" alwaysOn />
  </Filter>
);

const TimeField = (record) => {
  const time = record.record.time;
  const hour = time.split(":")[0] || "";
  const minutes = time.split(":")[1] || "";
  return (
    <span>
      {hour}:{minutes}
    </span>
  );
};

const WeekdayField = ({ record }) => {
  const day = record.weekday;
  let name;
  switch (day) {
    case "monday":
      name = "Poniedziałek";
      break;
    case "tuesday":
      name = "Wtorek";
      break;
    case "wednesday":
      name = "Środa";
      break;
    case "thursday":
      name = "Czwartek";
      break;
    case "friday":
      name = "Piątek";
      break;
    case "saturday":
      name = "Sobota";
      break;
    case "sunday":
      name = "Niedziela";
      break;
    default:
      name = "Nieznany";
  }
  return <span>{name}</span>;
};

export const ReservationsList = (props) => {
  setTitle("Rezerwacje");
  const displayDesktop = useMediaQuery("(min-width:1280px)");
  return (
    <List
      title="Rezerwacje"
      //   filter={{ "role.name": player }}
      {...props}
      filters={<UserListFilter />}
    >
      {displayDesktop ? (
        <Datagrid rowClick="edit">
          <AvatarField label="Avatar" />
          <TextField source="user.name" label="Imię" />
          <TextField source="user.surname" label="Nazwisko" />
          <WeekdayField label="Dzień tygodnia" />
          <TimeField label="Godzina" />
          <BooleanField source="isActive" label="Status" />
          <EditButton />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(record) => `${record.user.name} ${record.user.surname}`}
          secondaryText={(record) =>
            WeekdayField({
              record,
            })
          }
          tertiaryText={(record) => record.time}
          leftAvatar={(record) => (
            <img
              src={record.user.avatarUrl}
              height="60"
              width="60"
              alt="user avatar"
            />
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
        />
      )}
    </List>
  );
};

export const ReservationEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props} mutationMode="optimistic">
      <SimpleForm>
        <SelectInput
          source="weekday"
          choices={[
            { id: "monday", name: "Poniedziałek" },
            { id: "tuesday", name: "Wtorek" },
            { id: "wednesday", name: "Środa" },
            { id: "thursday", name: "Czwartek" },
            { id: "friday", name: "Piątek" },
            { id: "saturday", name: "Sobota" },
            { id: "sunday", name: "Niedziela" },
          ]}
          optionText="name"
          optionValue="id"
          label="Dzień tygodnia"
        />
        <TextInput source="time" type="time" label="Godzina" />
        <TextInput source="displayFrom" type="date" label="Wyświetlaj od" />
        <BooleanInput source="isActive" label="Aktywna" />
      </SimpleForm>
    </Edit>
  );
};

export const ReservationCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props} mutationMode="optimistic">
      <SimpleForm>
        <PlayerSelectAutocomplete name="user" label="Zawodnik" />
        <SelectInput
          source="weekday"
          choices={[
            { id: "monday", name: "Poniedziałek" },
            { id: "tuesday", name: "Wtorek" },
            { id: "wednesday", name: "Środa" },
            { id: "thursday", name: "Czwartek" },
            { id: "friday", name: "Piątek" },
            { id: "saturday", name: "Sobota" },
            { id: "sunday", name: "Niedziela" },
          ]}
          optionText="name"
          optionValue="id"
          label="Dzień tygodnia"
          alwaysOn
        />
        <TextInput source="time" type="time" label="Godzina" />
        <TextInput source="displayFrom" type="date" label="Wyświetlaj od" />
        <BooleanInput source="isActive" label="Aktywna" />
      </SimpleForm>
    </Create>
  );
};
