import React from "react";
import "./DashboardShortcut.css";

export const DashboardShortcut = ({ href, label, icon }) => {
  return (
    <a href={href} className={`dashboardShortcut`}>
      {icon}
      <span className="dashboardShortcut__label">{label}</span>
    </a>
  );
};
