import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Ubuntu", "Lato", "Roboto"],
  },
  palette: {
    primary: {
      main: '#141627',
    },
    secondary: {
      main: "#631970",
    },
  },
});
