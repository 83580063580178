// in src/users.js
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  BooleanField,
  NumberInput,
  BooleanInput,
  PasswordInput,
  Create,
  SelectInput,
  ReferenceInput,
  Filter,
  SimpleList,
} from "react-admin";
import { player } from "../../data/roles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setTitle } from "../../api/title";

const AvatarField = ({ source, record = {}, ...rest }) => {
  return (
    <img src={record.avatarUrl} height="60" width="60" alt="user avatar" />
  );
};

const UserListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Szukaj wg nazwiska" source="surname" alwaysOn />
  </Filter>
);
export const UserList = (props) => {
  setTitle("Zawodnicy");
  const displayDesktop = useMediaQuery("(min-width:1280px)");
  return (
    <List
      title="Zawodnicy"
      filter={{ "role.name": player }}
      {...props}
      filters={<UserListFilter />}
    >
      {displayDesktop ? (
        <Datagrid rowClick="edit">
          <AvatarField label="Avatar" />
          <TextField source="name" label="Imię" />
          <TextField source="surname" label="Nazwisko" />
          <NumberField source="phone" label="Telefon" />
          <BooleanField source="confirmed" label="Potwierdzony" />
          <BooleanField source="blocked" label="Zablokowany" />
          <EditButton />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(record) => `${record.name} ${record.surname}`}
          secondaryText={(record) => record.email}
          tertiaryText={(record) => record.phone}
          leftAvatar={(record) => (
            <img
              src={record.avatarUrl}
              height="60"
              width="60"
              alt="user avatar"
            />
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
        />
      )}
    </List>
  );
};

export const UserEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="Identyfikator" fullWidth />
        <TextInput disabled source="username" label="Login" fullWidth />
        <TextInput source="name" label="Imię" fullWidth />
        <TextInput source="surname" label="Nazwisko" fullWidth />
        <TextInput source="email" disabled label="Adres e-mail" fullWidth />
        <TextInput source="avatarUrl" label="URL Avatara" fullWidth />
        <NumberInput source="defaultPrice" label="Domyślna cena" fullWidth />
        <NumberInput source="phone" label="Numer telefonu" fullWidth />
        <ReferenceInput
          label="Sekcja"
          source="section"
          reference="training-types"
        >
          <SelectInput
            source="section"
            optionText="trainingTypeName"
            fullWidth
          />
        </ReferenceInput>
        <BooleanInput source="confirmed" label="Potwierdzony" />
        <BooleanInput source="blocked" label="Zablokowany" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" label="Login" fullWidth />
        <PasswordInput source="password" label="Hasło" fullWidth />
        <TextInput source="name" label="Imię" fullWidth />
        <TextInput source="surname" label="Nazwisko" fullWidth />
        <TextInput source="avatarUrl" label="URL Avatara" fullWidth />
        <TextInput source="email" label="Adres e-mail" fullWidth />
        <NumberInput source="defaultPrice" label="Domyślna cena" fullWidth />
        <NumberInput source="phone" label="Numer telefonu" fullWidth />
        <ReferenceInput
          label="Sekcja"
          source="section"
          reference="training-types"
        >
          <SelectInput
            source="section"
            optionText="trainingTypeName"
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
