import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  CardActions,
  Button,
} from "react-admin";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setTitle } from "../../../../api/title";
import dayjs from "dayjs";
import ListIcon from "@material-ui/icons/List";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import { PerformanceResultsFilterSidebar } from "../ResultsSidebar";
import { globalRefresh } from "../../../../helpers/globalRefresh";
import { ResultsGraph } from "../ResultsGraph";
import { getIdentity } from "../../../../helpers/getIdentity";

const RESULTS_VIEWS = {
  list: "list",
  graph: "graph",
};

const ListActions = ({
  basePath,
  match,
  setShowCreate,
  setResultsView,
  setShowSidebar,
  isList,
  isGraph,
}) => (
  <CardActions>
    {isList && (
      <Button
        onClick={() => setResultsView(RESULTS_VIEWS.graph)}
        label="Pokaz wykres"
      >
        <TrendingUpIcon />
      </Button>
    )}
    {isGraph && (
      <Button
        onClick={() => setResultsView(RESULTS_VIEWS.list)}
        label="Pokaz liste"
      >
        <ListIcon />
      </Button>
    )}
    <Button onClick={() => setShowSidebar(true)} label="Pokaz filtry">
      <FilterListIcon />
    </Button>
  </CardActions>
);

export const PlayerPerformanceResultsList = (props) => {
  setTitle("Wyniki");
  const [resultsView, setResultsView] = useState(RESULTS_VIEWS.graph);
  const [showSidebar, setShowSidebar] = useState(false);
  const displayDesktop = useMediaQuery("(min-width:1280px)");

  const isGraph = resultsView === RESULTS_VIEWS.graph;
  const isList = resultsView === RESULTS_VIEWS.list;
  const pagination = isGraph
    ? {
        pagination: false,
      }
    : {};
  const { id } = getIdentity();
  return (
    <>
      <List
        rowClick="show"
        filter={{ "user.id": id }}
        // filter={{ "role.name": player }}
        {...props}
        syncWithLocation
        basePath="/performance-results"
        {...pagination}
        aside={
          <PerformanceResultsFilterSidebar
            showSidebar={showSidebar}
            onClose={() => {
              setShowSidebar(false);
              globalRefresh();
            }}
          />
        }
        // filters={<PerformanceFilter />}
        actions={
          <ListActions
            {...props}
            setResultsView={setResultsView}
            isGraph={isGraph}
            isList={isList}
            setShowSidebar={setShowSidebar}
          />
        }
      >
        {isList ? (
          displayDesktop ? (
            <Datagrid rowClick="show">
              {/* <TextField source="id" label="ID" /> */}
              <TextField source="performance_type.typeName" label="Ćwiczenie" />
              <TextField source="value" label="Wartość" />
              <TextField source="performance_type.unit" label="Jednostka" />
            </Datagrid>
          ) : (
            <SimpleList
              primaryText={(record) => {
                return `${record.performance_type.typeName}`;
              }}
              secondaryText={(record) =>
                `${dayjs(record.date).format("DD/MM/YYYY HH:mm")}`
              }
              tertiaryText={(record) =>
                `${record.value} ${record.performance_type.unit}`
              }
              linkType={false}
            />
          )
        ) : (
          <ResultsGraph />
        )}
      </List>
    </>
  );
};
