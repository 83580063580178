import { usePermissions } from "react-admin";

import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import NavItem from "./components/NavItem";
import { listenFor } from "../events/events";
import { menu } from "./data/menu";
import { globalRefresh } from "../helpers/globalRefresh";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 71,
    height: "calc(100% - 71px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [identity, setIdentity] = useState({
    displayName: "Niezidentyfikowano",
    section: "Nieznana sekcja",
    avatarUrl: "",
  });
  const { permissions } = usePermissions();
  useEffect(() => {
    setItems(menu[permissions]);
  }, [permissions]);
  useEffect(() => {
    listenFor("toggleMobileMenu", (detail) => {
      setOpenMenu(detail.state);
    });
    const identity = JSON.parse(localStorage.getItem("identity"));
    setIdentity({
      displayName: `${identity.name} ${identity.surname}`,
      section: identity.section.trainingTypeName,
      avatarUrl: identity.avatarUrl,
    });
  }, []);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={identity.avatarUrl}
          to="/app/account"
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {identity.displayName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {identity.section}
        </Typography>
      </Box>
      <Divider />

      {items && items.length > 0 && (
        <Box p={2}>
          <List>
            {items.map(
              (item) =>
                item.display && (
                  <NavItem
                    href={item.href || ""}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    onClick={() => {
                      setTimeout(() => {
                        setOpenMenu(false);
                      }, 200);
                      if (item.onClick) {
                        item.onClick();
                      }
                    }}
                    active
                  />
                )
            )}
          </List>
        </Box>
      )}
      <Hidden mdDown>
        <Box flexGrow={1} />
        <Box p={2} m={2} bgcolor="background.dark">
          <Typography align="center" variant="body2">
            Więcej informacji znajdziesz na naszej stronie internetowej!
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              color="primary"
              component="a"
              href="http://hugonacademy.pl"
              variant="contained"
            >
              Zobacz więcej
            </Button>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => {
            setOpenMenu(false);
            globalRefresh();
          }}
          open={openMenu}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export const HASidebar = (props) => {
  return <NavBar {...props} />;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
