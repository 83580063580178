import * as React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { FilterList, FilterListItem } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfToday,
  endOfWeek,
  endOfMonth,
} from "date-fns";
import { CardContent, Drawer, makeStyles } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import { fetchRequest } from "../../../api/fetchRequest";
const DatesFilter = () => (
  <FilterList label="Data" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Dzisiaj"
      value={{
        date_gte: endOfYesterday().toISOString(),
        date_lte: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="Ten tydzień"
      value={{
        date_gte: startOfWeek(new Date()).toISOString(),
        date_lte: endOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ostatni tydzień"
      value={{
        date_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        date_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ten miesiąc"
      value={{
        date_gte: startOfMonth(new Date()).toISOString(),
        date_lte: endOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ostatni miesiąc"
      value={{
        date_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        date_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Wcześniej"
      value={{
        date_gte: undefined,
        date_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);

const PerformanceResultsTypes = () => {
  const [performanceTypes, setPerformanceTypes] = React.useState([]);
  React.useEffect(
    () =>
      fetchRequest(`/performance-types`).then((data) => {
        setPerformanceTypes(
          data.map(({ id, typeName, unit }) => ({
            id,
            typeName,
            unit,
          }))
        );
      }),
    []
  );
  return (
    <FilterList label="Ćwiczenia" icon={<TuneIcon />}>
      {performanceTypes.map((type) => (
        <FilterListItem
          label={type.typeName}
          value={{
            "performance_type.typeName": type.typeName,
          }}
        />
      ))}
    </FilterList>
  );
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
}));

export const PerformanceResultsFilterSidebar = ({ showSidebar, onClose }) => {
  const classes = useStyles();
  return (
    <Drawer
      open={showSidebar}
      onClose={onClose}
      anchor="right"
      variant="temporary"
      classes={{ paper: classes.mobileDrawer }}
    >
      {/* <Card> */}
      <CardContent>
        {/* <FilterLiveSearch source="user.name" label="Imię" /> */}
        {/* <FilterLiveSearch source="user.surname" label="Nazwisko" /> */}
        <PerformanceResultsTypes />
        <DatesFilter />
      </CardContent>
      {/* </Card> */}
    </Drawer>
  );
};
