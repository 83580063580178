import React, { useRef } from "react";
import dayjs from "dayjs";
import { Axis, Tooltip, Chart, LineAdvance } from "bizcharts";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./ResultsGraph.css";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");

const getData = (data) => {
  const results = Object.values(data)
    .map((entry) => ({
      date: dayjs(entry.date).format("DD-MM-YYYY"),
      value: entry.value,
      name: entry.performance_type.typeName,
      unit: entry.performance_type.unit,
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  return results;
};

export const ResultsGraph = (props) => {
  const data = getData(props.data);
  const chartContainer = useRef();
  const displayMobile = useMediaQuery("(max-width:600px)");
  // Nie ma wybranego ćwiczenia
  if (Object.keys(props.filterValues).length === 0) {
    return (
      <span
        style={{
          display: "flex",
          margin: "20px",
          justifyContent: "center",
        }}
      >
        Wybierz ćwiczenie z listy filtrów
      </span>
    );
  }
  if (data.length === 0) {
    return (
      <span
        style={{
          display: "flex",
          margin: "20px",
        }}
      >
        Brak danych
      </span>
    );
  }

  const sortedData = [...data].sort((a, b) => a.value - b.value);
  const unit = data[0].unit;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        background: "white",
      }}
      id="graphElement"
    >
      <div id="chart-container" ref={chartContainer}>
        <Chart
          padding={[50, 30, 50, 60]}
          autoFit
          height={displayMobile ? "100%" : 550}
          data={data}
          scale={{
            value: {
              min: sortedData[0] - 2 > 0 ? sortedData[0] - 2 : 0,
              max: sortedData[sortedData.length - 1].value + 2,
            },
            date: {
              formatter: (value) => {
                return value.replaceAll("-", ".");
              },
            },
          }}
        >
          <Tooltip
            useHtml
            title={(date) => `Wynik z dnia ${date}`}
            domStyles={{
              "g2-tooltip": {
                boxShadow: "none",
                color: "#fff",
                backgroundColor: "#222",
              },
            }}
            crosshairs={{
              type: "y",
            }}
            style={{
              color: "red",
            }}
          />
          <Axis
            name="date"
            grid={{
              line: {
                style: {
                  stroke: "#efdff2",
                  lineDash: [2, 2],
                },
              },
            }}
          />
          <Axis
            name="value"
            label={{
              formatter: (tick) => {
                return `${tick} ${unit}`;
              },
            }}
            grid={{
              line: {
                style: {
                  stroke: "#efdff2",
                  lineDash: [2, 2],
                },
              },
            }}
          />
          <LineAdvance
            shape="smooth"
            point
            area
            position="date*value"
            color="#141627"
            animate={{
              appear: {
                animation: "wave-in",
                duration: 2000,
                easing: "easeCubicInOut",
              },
            }}
            tooltip={[
              "date*value",
              (date, value) => {
                return {
                  name: "Wartość",
                  value: `${value} ${unit}`,
                  date,
                };
              },
            ]}
          />
        </Chart>
      </div>
    </div>
  );
};
