import React from "react";
import { player } from "../../../data/roles";
import {
  List,
  Datagrid,
  TextField,
  Resource,
  SimpleShowLayout,
  Show,
  Filter,
  TextInput,
  SimpleList,
} from "react-admin";
import { Route } from "react-router-dom";
import { PlayerResultsList } from "./PlayerResultsList";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setTitle } from "../../../api/title";

const PerformanceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const BasicList = (props) => {
  setTitle("Wyniki");
  const displayDesktop = useMediaQuery("(min-width:1280px)");
  return (
    <>
      <Route path="/performance-results">
        {({ match }) => {
          if (!match.isExact) {
            return null;
          }
          return (
            <List
              rowClick="show"
              filter={{ "role.name": player }}
              {...props}
              filters={<PerformanceFilter />}
              syncWithLocation
              basePath="/performance-results"
            >
              {displayDesktop ? (
                <Datagrid rowClick="show">
                  <TextField source="name" label="Imię" />
                  <TextField source="surname" label="Nazwisko" />
                  <TextField source="section.trainingTypeName" label="Sekcja" />
                </Datagrid>
              ) : (
                <SimpleList
                  primaryText={(record) => `${record.name} ${record.surname}`}
                  secondaryText={(record) => record.section.trainingTypeName}
                  leftAvatar={(record) => (
                    <img
                      src={record.avatarUrl}
                      height="60"
                      width="60"
                      alt="user avatar"
                    />
                  )}
                  linkType="show"
                />
              )}
            </List>
          );
        }}
      </Route>
      <Route path="/performance-results/:id/show">
        {({ match, ...props }) =>
          match && (
            <div>
              <PlayerResultsList match={match} {...props} />
            </div>
          )
        }
      </Route>
    </>
  );
};

const BasicShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

export const ResultsList = () => {
  return <Resource name="users" list={BasicList} show={BasicShow} />;
};
