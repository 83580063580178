import React from "react";
import "./Settings.css";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { fetchRequest } from "../../api/fetchRequest";
import { getIdentity } from "../../helpers/getIdentity";
import Swal from "sweetalert2";

export const Settings = () => {
  const formRef = React.useRef(null);
  const [message,setMessage] = React.useState(null);

  const changePassword = (e) => {
    e.preventDefault();
    setMessage('');
    const newPassword = e.target['newPassword'].value;
    const newConfirmed = e.target['newConfirmed'].value;
    
    if (newPassword !== newConfirmed) {
      return setMessage('Hasła muszą się zgadzać')
    }
    const identity = getIdentity();
    fetchRequest(`/users/${identity.id}`, {
      password: newPassword,
      passwordConfirmatiion: newPassword,
    }, {
      method: 'PUT'
    }).then(() => {
      Swal.fire('Sukces', 'Hasło zostało zmienione', 'success');
      formRef.current.reset();
    }).catch((err) => setMessage('Nieznany błąd'))
  }

  return (
    <Paper>
      <div id="settings-container">
        <strong
          style={{
            marginBottom: 20,
          }}
        >
          Zmień hasło
        </strong>
        
        <form ref={formRef} id="password-form" onSubmit={changePassword}>
          <TextField name="newPassword" label="Nowe hasło"  type="password" inputProps={{ minLength: 6, maxLength: 12, required: true }}/>
          <TextField name="newConfirmed" label="Potwierdź nowe hasło"  type="password" style={{ marginTop:10}} inputProps={{ minLength: 6, maxLength: 12, required: true }}/>
          <Button variant="contained" color="primary" style={{marginTop:20,}} type="submit">Zmień hasło</Button>
          {message && <strong style={{
            marginTop: 20,
            color: 'red'
          }}>{message}</strong>}
        </form>
      </div>
    </Paper>
  );
};
