import React from "react";
import { admin } from "../../data/roles";
import AdminDashboard from "./AdminDashboard";
import PlayerDashboard from "./PlayerDashboard";

export const Dashboard = ({ permissions }) => {
  if (permissions === admin) {
    return <AdminDashboard />;
  }
  return <PlayerDashboard />;
};
