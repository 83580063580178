import config from "../config";
import Cookies from "js-cookie";

export const fetchRequest = (endpoint, data = null, options = {}) => {
  const token = Cookies.get("token");
  const body = data
    ? {
        body: JSON.stringify(data),
      }
    : {};
  return fetch(`${config.strapiUrl}${endpoint}`, {
    method: options.method || "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...body,
  })
    .then((data) => {
      return data.json();
      // notify('Comment approved');
      // redirect('/comments');
    })
    .catch((e) => {
      console.error(e);
      // notify('Error: comment not approved', 'warning')
    })
    .finally(() => {
      // setLoading(false);
      // dispatch(fetchEnd()); // stop the global loading indicator
    });
};
