/* eslint-disable import/no-anonymous-default-export */
import Cookies from "js-cookie";
import config from "../config";

export default {
  login: ({ username, password }) => {
    const identifier = username; // strapi expects 'identifier' and not 'username'
    const request = new Request(`${config.strapiUrl}/auth/local`, {
      method: "POST",
      body: JSON.stringify({ identifier, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        localStorage.setItem("identity", JSON.stringify(response.user));
        Cookies.set("token", response.jwt, 1);
        Cookies.set("role", response.user.role.name, 1);
        Cookies.set(
          "ha-username",
          `${response.user.name} ${response.user.surname}`
        );
        Cookies.set("ha-avatar", response.user.avatarUrl);
        Cookies.set("ha-id", response.user.id);
      });
  },

  logout: () => {
    Cookies.remove("token");
    Cookies.remove("role");
    Cookies.remove("ha-username");
    Cookies.remove("ha-avatar");
    Cookies.remove("ha-id");
    return Promise.resolve();
  },

  checkAuth: () => {
    return Cookies.get("token") ? Promise.resolve() : Promise.reject();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookies.remove("token");
      Cookies.remove("role");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = Cookies.get("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
