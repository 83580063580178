import React from "react";
import { useInput } from "react-admin";
import { default as MuiTextField } from "@material-ui/core/TextField";
import { player, trainer } from "../../../data/roles";
import { fetchRequest } from "../../../api/fetchRequest";
import { AutocompleteInput } from "react-admin";
import "./inputs.css";

export const PriceTextField = (props) => {
  const {
    input: { onChange, name, value },
    meta: { touched, error },
  } = useInput(props);
  let input;
  React.useEffect(() => {
    onChange(props.defaultValue);
  }, [props.defaultValue, input, onChange]);
  return (
    <MuiTextField
      name={name}
      value={value}
      label={props.label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
    />
  );
};

export const TrainerSelectAutocomplete = (props) => {
  const [choices, setChoices] = React.useState([]);
  React.useEffect(
    () =>
      fetchRequest(
        `/users?_sort=id:ASC&_start=0&_limit=100&role.name=${trainer}`
      ).then((data) => {
        setChoices(
          data.map(({ id, name, surname }) => ({
            id,
            name: `${name} ${surname}`,
          }))
        );
      }),
    []
  );
  return (
    <AutocompleteInput className="autocomplete" choices={choices} {...props} />
  );
};

export const PlayerSelectAutocomplete = (props) => {
  // console.log("default player", props.defaultValue);
  const [choices, setChoices] = React.useState([]);
  React.useEffect(
    () =>
      fetchRequest(
        `/users?_sort=id:ASC&_start=0&_limit=100&role.name=${player}`
      ).then((data) => {
        setChoices(
          data.map(({ id, name, surname, defaultPrice }) => ({
            id,
            name: `${name} ${surname}`,
            defaultPrice,
          }))
        );
      }),
    []
  );
  return (
    <AutocompleteInput className="autocomplete" choices={choices} {...props} />
  );
};
