import React, { Fragment } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";

import {
  Datagrid,
  List,
  TextField,
  CardActions,
  CreateButton,
  EditButton,
  SimpleList,
} from "react-admin";
import { Route } from "react-router";
import { Drawer } from "@material-ui/core";
import TrainingTypeCreate from "./TrainingTypeCreate";
import TrainingTypeEdit from "./TrainingTypeEdit";

import { ColorField } from "react-admin-color-input";
import { setTitle } from "../../api/title";

const styles = {
  drawerContent: {
    width: 300,
  },
};

const TrainingTypeListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);
class TrainingTypeList extends React.Component {
  render() {
    setTitle("Sekcje");
    const displayDesktop = window.matchMedia("(min-width:1280px)").matches;
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          sort={{ field: "name", order: "ASC" }}
          actions={<TrainingTypeListActions />}
        >
          {displayDesktop ? (
            <Datagrid>
              <TextField source="trainingTypeName" label="Nazwa" />
              <ColorField source="color" label="Oznaczenie" />
              <EditButton />
            </Datagrid>
          ) : (
            <SimpleList
              primaryText={(record) => {
                return `${record.trainingTypeName}`;
              }}
              leftAvatar={(record) => (
                <span
                  height="60"
                  width="60"
                  alt="training type avatar"
                  style={{
                    backgroundColor: record.color,
                    height: 60,
                    width: 60,
                    borderRadius: 30,
                  }}
                />
              )}
              linkType="edit"
            />
          )}
        </List>
        <Route path="/training-types/create">
          {({ match }) => (
            <Drawer open={!!match} anchor="right" onClose={this.handleClose}>
              <TrainingTypeCreate
                className={classes.drawerContent}
                onCancel={this.handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route path="/training-types/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer open={isMatch} anchor="right" onClose={this.handleClose}>
                {isMatch ? (
                  <TrainingTypeEdit
                    className={classes.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }

  handleClose = () => {
    this.props.push("/training-types");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(TrainingTypeList);
