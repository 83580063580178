import React, { Fragment } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";

import {
  Datagrid,
  List,
  CardActions,
  CreateButton,
  EditButton,
  Button,
  SimpleList,
} from "react-admin";
import { Route } from "react-router";
import { Drawer } from "@material-ui/core";
import TrainingCreate from "./TrainingCreate";
import TrainingEdit from "./TrainingEdit";
import {
  FullNameField,
  PaymentField,
  TrainingDate,
  TrainingHappenedField,
} from "./customs/CustomFields";
import { FilterSidebar } from "./filtersSidebar";
import TodayIcon from "@material-ui/icons/Today";
import { dispatchCustomEvent, listenFor } from "../../events/events";
import { Calendar } from "./calendar";
import ListIcon from "@material-ui/icons/List";
import FilterListIcon from "@material-ui/icons/FilterList";
import dayjs from "dayjs";
import { globalRefresh } from "../../helpers/globalRefresh";
import { setTitle } from "../../api/title";
import { getIdentity } from "../../helpers/getIdentity";
import { isPlayer } from "../../helpers/isPlayer";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");
const styles = {
  drawerContent: {
    width: 300,
  },
};

const AvatarField = ({ source, record = {}, ...rest }) => {
  return (
    <img
      src={record.player.avatarUrl}
      height="60"
      width="60"
      alt="user avatar"
    />
  );
};

const TrainingListActions = ({ basePath, activeView, setShowFilters }) => (
  <CardActions>
    {!isPlayer() && (
      <Button
        onClick={() => {
          dispatchCustomEvent("setTrainingsView", {
            view:
              activeView === TRAININGS_VIEWS.list
                ? TRAININGS_VIEWS.calendar
                : TRAININGS_VIEWS.list,
          });
        }}
        label={activeView === TRAININGS_VIEWS.list ? "Kalendarz" : "Lista"}
      >
        {activeView === TRAININGS_VIEWS.list ? <TodayIcon /> : <ListIcon />}
      </Button>
    )}
    <Button
      onClick={() => {
        setShowFilters(true);
      }}
      label="Pokaź filtry"
    >
      <FilterListIcon />
    </Button>
    {!isPlayer() && <CreateButton basePath={basePath} label="Dodaj trening" />}
  </CardActions>
);

const TRAININGS_VIEWS = {
  calendar: "calendar",
  list: "list",
};
class TrainingList extends React.Component {
  constructor() {
    super();
    listenFor("setTrainingsView", (details) => {
      this.setState({
        activeView: details.view,
      });
    });
    this.state = {
      activeView: TRAININGS_VIEWS.calendar,
      showFilters: false,
    };
  }
  setShowFilters = (showFilters) => this.setState({ showFilters });

  handleClose = () => {
    this.props.push("/trainings");
  };
  render() {
    setTitle("Treningi");
    const { push, classes, ...props } = this.props;
    const pagination =
      this.state.activeView === TRAININGS_VIEWS.calendar
        ? { pagination: false }
        : {};
    const queryFilters = isPlayer()
      ? {
          filter: {
            "player.id": getIdentity()?.id,
          },
        }
      : {};
    const displayDesktop = window.matchMedia("(min-width:1280px)").matches;
    return (
      <Fragment>
        <List
          {...props}
          sort={{ field: "date", order: "DESC" }}
          {...queryFilters}
          actions={
            <TrainingListActions
              activeView={this.state.activeView}
              setShowFilters={this.setShowFilters}
            />
          }
          aside={
            <FilterSidebar
              showFilters={this.state.showFilters}
              onClose={() => {
                this.setShowFilters(false);
                globalRefresh();
              }}
            />
          }
          {...pagination}
        >
          {this.state.activeView === TRAININGS_VIEWS.list ? (
            displayDesktop ? (
              <Datagrid>
                <AvatarField label="Avatar" />
                <FullNameField source="player" label="Zawodnik" />
                <TrainingDate label="Data" />
                <PaymentField label="Płatność" />
                <TrainingHappenedField label="Realizacja" />
                <EditButton />
              </Datagrid>
            ) : (
              <SimpleList
                primaryText={(record) => {
                  return `${record.player.name} ${record.player.surname}`;
                }}
                secondaryText={(record) =>
                  `${dayjs().from(dayjs(record.date))} (${dayjs(
                    record.date
                  ).format("DD/MM/YYYY")})`
                }
                leftAvatar={(record) => (
                  <img
                    src={record.player.avatarUrl}
                    height="60"
                    width="60"
                    alt="user avatar"
                  />
                )}
                tertiaryText={(record) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>{record.didHappen ? "✅" : "❌"} &nbsp; 🏃‍♂️</span>
                    <span>{record.isPaid ? "✅" : "❌"} &nbsp; 💵</span>
                  </div>
                )}
                linkType={isPlayer() ? "show" : "edit"}
              />
            )
          ) : (
            <Calendar />
          )}
        </List>
        {!isPlayer() && (
          <Route path="/trainings/create">
            {({ match }) => (
              <Drawer open={!!match} anchor="right" onClose={this.handleClose}>
                <TrainingCreate
                  className={classes.drawerContent}
                  onCancel={this.handleClose}
                  {...props}
                />
              </Drawer>
            )}
          </Route>
        )}
        <Route path="/trainings/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer open={isMatch} anchor="right" onClose={this.handleClose}>
                {isMatch ? (
                  <TrainingEdit
                    className={classes.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(TrainingList);
