import { Layout } from "react-admin";
import { HAAppBar } from "./AppBar";
import { HASidebar } from "./Sidebar";

const HALayout = (superProps) => {
  return (
    <Layout
      {...superProps}
      appBar={HAAppBar}
      sidebar={HASidebar}
    />
  );
};

export default HALayout;
