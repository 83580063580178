/* eslint-disable no-loop-func */
import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./calendar.css";
import dayjs from "dayjs";

import { fetchRequest } from "../../api/fetchRequest";
import plLocale from "@fullcalendar/core/locales/pl";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import cloneDeep from "clone-deep";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getIdentity } from "../../helpers/getIdentity";
import { isPlayer } from "../../helpers/isPlayer";
import Swal from "sweetalert2";
import { TrainingModal } from "./TrainingModal";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");

const mapWeekdayToNumber = (dayName) => {
  switch (dayName) {
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    case "sunday":
      return 0;
    default:
      return 0;
  }
};

const getPermanentReservations = (sections, trainings) => {
  const player = isPlayer();
  return fetchRequest(
    `/permanent-reservations?isActive=true${
      player ? `&user.id=${getIdentity().id}` : ""
    }`
  ).then((data) => {
    const res = [];
    data.forEach((reservation) => {
      res.push({
        id: reservation.id,
        borderColor: sections[reservation?.user?.section]?.color,
        title: `${reservation?.user?.surname} ${
          reservation?.user?.name
            ? reservation?.user?.name[0].toUpperCase()
            : ""
        }.`,
        weekday: mapWeekdayToNumber(reservation.weekday),
        startTime: reservation.time,
        classNames: "fc-event-reservation",
        extendedProps: {
          isReservation: true,
          userId: reservation?.user?.id,
          user: reservation?.user,
          defaultPrice: reservation?.user?.defaultPrice,
        },
        displayFrom: reservation?.displayFrom,
      });
    });
    return res;
  });
};

const convertDates = (data, sections) => {
  const converted = Object.values(data).map((training) => {
    const name = training?.player?.name;
    return {
      id: training.id,
      borderColor: sections[training?.player?.section]?.color,
      title: `${training?.player?.surname} ${
        name ? name[0].toUpperCase() : ""
      }.`,
      start: dayjs(training.date).add(0, "hour").format(),
      end: dayjs(training.date).add(1, "hour").format(),
      extendedProps: {
        training,
      },
    };
  });
  return converted;
};

const debugCalendar = (...logProps) => {
  return; // Comment if want to debug
  // eslint-disable-next-line no-unreachable
  console.log(logProps);
};
export const Calendar = (props) => {
  const calendarRef = React.useRef(false);
  const [trainingModalState, setTrainingModalState] = React.useState(false);
  const [trainingModalData, setTrainingModalData] = React.useState({});
  const [sections, setSections] = React.useState({});
  const [events, setEvents] = React.useState([]);
  const [allReservations, setAllReservations] = React.useState([]);
  const displayDesktop = useMediaQuery("(min-width:1280px)");

  const setCalendarEvents = React.useCallback(() => {
    const trainings = convertDates(props.data, sections);
    getPermanentReservations(sections, trainings).then((data) => {
      const calendarApi = calendarRef.current.getApi();
      window.calendarApi = calendarApi;
      window.calendar = calendarRef.current;
      const startDate = calendarApi.view.activeStart;
      const endDate = calendarApi.view.activeEnd;
      debugCalendar({ startDate, endDate });
      const reservationsToMark = [];
      data.forEach((reservation) => {
        for (
          //przedzial aktualnie wybrany na kalendarzu
          let day = dayjs(startDate);
          day.isBefore(endDate);
          day = day.add(1, "day")
        ) {
          debugCalendar("DLA ", day.format("YYYY-MM-DD"));
          try {
            const isReservationForThisWeekday =
              Number(day.format("d")) === reservation.weekday;
            debugCalendar("Rezerwacja:", isReservationForThisWeekday);
            if (isReservationForThisWeekday) {
              const dateOfThisReservation = day
                .hour(Number(reservation.startTime.split(":")[0]) + 1)
                .minute(0);
              debugCalendar("Na:", dateOfThisReservation);
              const shouldReservationBeDisplayed =
                dateOfThisReservation.diff(dayjs(reservation.displayFrom)) > 0;
              //
              const isTrainingFromReservation = trainings.find((training) => {
                return (
                  dateOfThisReservation.diff(
                    dayjs(training.start).add(1, "hour")
                  ) === 0 &&
                  day.format("YYYY-MM-DD") ===
                    dayjs(training.start).format("YYYY-MM-DD")
                );
              });
              debugCalendar("Trening z rezerwacji:", isTrainingFromReservation);
              if (!isTrainingFromReservation && shouldReservationBeDisplayed) {
                const reservationCopy = cloneDeep(reservation);
                debugCalendar("Powinienem dodać tu rezerwację");
                reservationCopy.start = dayjs(dateOfThisReservation)
                  .subtract(1, "hour")
                  .toDate();
                debugCalendar(dateOfThisReservation);
                reservationCopy.end = dayjs(dateOfThisReservation)
                  .subtract(1, "hour")
                  .toDate();
                delete reservationCopy.startTime;
                delete reservationCopy.endTime;
                reservationsToMark.push(reservationCopy);
              }
            }
          } catch (e) {
            debugCalendar(e, reservation);
          }
          debugCalendar("---------------------------------");
        }
      });
      setAllReservations(reservationsToMark);
    });
    setEvents(trainings);
  }, [props.data, sections]);
  //
  React.useEffect(() => {
    setCalendarEvents();
  }, [setCalendarEvents]);

  //
  React.useEffect(() => {
    fetchRequest("/training-types").then((data) => {
      const res = {};
      data.forEach((section) => {
        res[section._id] = {
          color: section.color,
          name: section.trainingTypeName,
        };
      });
      setSections(res);
    });
  }, []);
  return (
    <div className="fc-container-scroll-harness">
      <div className="fc-container">
        <FullCalendar
          timeZone="local"
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          initialView={
            localStorage.getItem("calendarView")
              ? localStorage.getItem("calendarView")
              : "listWeek"
          }
          dateClick={(data) =>
            !isPlayer()
              ? (window.location.hash = `/trainings/create?date=${data.dateStr},08:00`)
              : null
          }
          height="100%"
          ref={calendarRef}
          events={[...events, ...allReservations]}
          dayMaxEvents={!displayDesktop ? 4 : 8}
          contentHeight="auto"
          viewDidMount={(data) => {
            localStorage.setItem("calendarView", data.view.type);
          }}
          eventClick={({ event }) => {
            if (isPlayer()) {
              if (event.extendedProps?.isReservation) {
                Swal.fire("", "Trening niezatwierdzony", "info");
                return;
              }
              setTrainingModalData(event.extendedProps);
              setTrainingModalState(true);
            } else {
              if (event.extendedProps?.isReservation) {
                window.location.hash = `/trainings/create?user=${
                  event.extendedProps.userId
                }&defaultPrice=${event.extendedProps.defaultPrice}&date=${dayjs(
                  event.start
                ).format("YYYY-MM-DD,HH:mm")}`;
              } else {
                setTrainingModalData(event.extendedProps);
                setTrainingModalState(true);
              }
            }
          }}
          headerToolbar={{
            start: "title",
            center: "",
            end: "today prev,next listWeek,dayGridMonth",
          }}
          locale={plLocale}
        />
        <TrainingModal
          setTrainingModalState={setTrainingModalState}
          trainingModalData={trainingModalData}
          isOpen={trainingModalState}
          sections={sections}
        />
      </div>
    </div>
  );
};
