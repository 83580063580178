export const listenFor = (name, callback) =>
  window.document.addEventListener(name, function (e) {
    callback(e.detail);
  });

export const dispatchCustomEvent = (name, details) => {
  var event = new CustomEvent(name, {
    detail: {
      ...details,
    },
  });
  window.document.dispatchEvent(event);
};
