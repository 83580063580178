import React from "react";
import {
  Create,
  SimpleForm,
  SaveButton,
  Toolbar,
  translate,
  DateTimeInput,
  BooleanInput,
} from "react-admin";
import Button from "@material-ui/core/Button";
import RichTextInput from "ra-input-rich-text";
import {
  PlayerSelectAutocomplete,
  PriceTextField,
} from "./customs/CustomInputs";
import { globalRefresh } from "../../helpers/globalRefresh";
const TrainingCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
  </Toolbar>
));

const TrainingCreate = ({ onCancel, ...props }) => {
  const queryParams = new URLSearchParams(window.location.hash.substr(19));
  const predefinedPrice = queryParams.get("defaultPrice");
  const predefinedDate = queryParams.get("date")
    ? queryParams.get("date")
    : new Date();
  const predefinedUser = queryParams.get("user");
  const [defaultPrice, setDefaultPrice] = React.useState(predefinedPrice);
  return (
    <Create
      title=" "
      {...props}
      mutationMode="pessimistic"
      onSuccess={() => {
        globalRefresh();
        onCancel();
      }}
    >
      <SimpleForm toolbar={<TrainingCreateToolbar onCancel={onCancel} />}>
        {/* Still working !! */}
        {/* <TrainerSelectAutocomplete label="Trener" name="trainer" /> */}
        <PlayerSelectAutocomplete
          label="Zawodnik"
          name="player"
          onSelect={({ defaultPrice }) => setDefaultPrice(defaultPrice)}
          defaultValue={predefinedUser}
        />
        <DateTimeInput
          source="date"
          label="Data i czas"
          defaultValue={new Date(predefinedDate)}
        />
        <div
          style={{
            marginTop: "-10px",
            marginBottom: 20,
          }}
        >
          <PriceTextField
            name="price"
            label="Kwota"
            defaultValue={defaultPrice}
          />
        </div>
        <BooleanInput source="didHappen" label="Trening zrealizowany" />
        <BooleanInput source="isPaid" label="Platność zrealizowana" />
        <RichTextInput
          label="Notatki"
          source="notes"
          toolbar={[["bold", "italic", "underline"]]}
        />
      </SimpleForm>
    </Create>
  );
};

export default TrainingCreate;
