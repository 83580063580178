import * as React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { FilterList, FilterListItem } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfToday,
  endOfWeek,
  endOfMonth,
} from "date-fns";
import { CardContent, Drawer, makeStyles } from "@material-ui/core";

// @TODO: RODZAJE TRENINGOW FILTROWANIE
const DatesFilter = () => (
  <FilterList label="Data" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Dzisiaj"
      value={{
        date_gte: endOfYesterday().toISOString(),
        date_lte: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="Ten tydzień"
      value={{
        date_gte: startOfWeek(new Date()).toISOString(),
        date_lte: endOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ostatni tydzień"
      value={{
        date_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        date_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ten miesiąc"
      value={{
        date_gte: startOfMonth(new Date()).toISOString(),
        date_lte: endOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Ostatni miesiąc"
      value={{
        date_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        date_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Wcześniej"
      value={{
        date_gte: undefined,
        date_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);
const IsPaidFilter = () => (
  <FilterList label="Płatność" icon={<MonetizationOnIcon />}>
    <FilterListItem
      label="Zrealizowana"
      value={{
        isPaid: true,
      }}
    />
    <FilterListItem
      label="Niezrealizowana"
      value={{
        isPaid: false,
      }}
    />
  </FilterList>
);
const DidHappenFilter = () => (
  <FilterList label="Trening" icon={<DirectionsRunIcon />}>
    <FilterListItem label="Zrealizowano" value={{ didHappen: true }} />
    <FilterListItem label="Niezrealizowano" value={{ didHappen: false }} />
  </FilterList>
);

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
}));

export const FilterSidebar = ({ showFilters, onClose }) => {
  const classes = useStyles();
  return (
    <Drawer
      open={showFilters}
      onClose={onClose}
      anchor="right"
      variant="temporary"
      classes={{ paper: classes.mobileDrawer }}
    >
      <CardContent>
        <DatesFilter />
        <IsPaidFilter />
        <DidHappenFilter />
      </CardContent>
    </Drawer>
  );
};
