import React from "react";
import { AutocompleteInput } from "react-admin";
import { fetchRequest } from "../../../api/fetchRequest";

export const PerformanceTypeSelectAutocomplete = (props) => {
  const [choices, setChoices] = React.useState([]);
  React.useEffect(
    () =>
      fetchRequest(`/performance-types?_sort=id:ASC&_start=0&_limit=100`).then(
        (data) => {
          setChoices(
            data.map(({ id, typeName, unit }) => ({
              id,
              name: typeName,
              unit,
            }))
          );
        }
      ),
    []
  );
  return (
    <AutocompleteInput
      className="autocomplete"
      choices={choices}
      {...props}
      onSelect={(data) => {
        if (props.onSelect) {
          props.onSelect(data);
        }
      }}
    />
  );
};
