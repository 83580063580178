import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { ColorInput } from "react-admin-color-input";

const TrainingTypeCreateToolbar = translate(
  ({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
      <SaveButton />
      <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
    </Toolbar>
  )
);

const TrainingTypeCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props} mutationMode="optimistic">
    <SimpleForm toolbar={<TrainingTypeCreateToolbar onCancel={onCancel} />}>
      <ColorInput source="color" validate={required()} />
      <TextInput source="trainingTypeName" validate={required()} />
    </SimpleForm>
  </Create>
);

export default TrainingTypeCreate;
