import * as React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import { UserCreate, UserEdit, UserList } from "./views/users/users";
import authProvider from "./providers/authProvider";
import { admin, player, trainer } from "./data/roles";
import simpleRestProvider from "ra-strapi-rest";
import Cookies from "js-cookie";
import config from "./config";
import UserIcon from "@material-ui/icons/People";
import TuneIcon from "@material-ui/icons/Tune";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TimelineIcon from "@material-ui/icons/Timeline";
import trainingTypes from "./views/trainingTypes";
import trainings from "./views/trainings";
import { Route } from "react-router-dom";
import {
  PerformanceTypeCreate,
  PerformanceTypeEdit,
  PerformanceTypesList,
} from "./views/performance/results/types/types";
import { ResultsList } from "./views/performance/results/ResultsList";
import { PlayerPerformanceResultsList } from "./views/performance/results/player/PlayerPerformanceResultsList";
import { theme } from "./theme";
import HALayout from "./layouts/Layout";
import polyglotI18nProvider from "ra-i18n-polyglot";
import polishMessages from "ra-language-polish";
import "./App.css";
import { LoginPage } from "./views/LoginPage/LoginPage";
import { globalRefresh } from "./helpers/globalRefresh";
import {
  ReservationCreate,
  ReservationEdit,
  ReservationsList,
} from "./views/permanentReservations/permanentReservations";
import TrainingList from "./views/trainings/TrainingList";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Settings } from "./views/settings/Settings";
require("dayjs/locale/pl");

const messages = {
  pl: polishMessages,
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = Cookies.get("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = simpleRestProvider(config.strapiUrl, httpClient, [
  "images",
]);

const i18nProvider = polyglotI18nProvider(() => polishMessages, "pl");

const App = () => {
  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      authProvider={authProvider}
      ready={() => globalRefresh()}
      layout={HALayout}
      locale="pl"
      messages={messages}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      customRoutes={[<Route path="/settings" component={Settings} />]}
    >
      {(permissions) => {
        const isAdmin = permissions === admin;
        const isTrainer = permissions === trainer;
        const isPlayer = permissions === player;
        const adminOnly = isAdmin
          ? [
              <Resource
                name="users"
                options={{ label: "Zawodnicy" }}
                icon={UserIcon}
                list={UserList}
                edit={UserEdit}
                create={UserCreate}
              />,
              <Resource
                options={{ label: "Wyniki" }}
                icon={TimelineIcon}
                name="performance-results"
                list={ResultsList}
              />,
              <Resource
                options={{ label: "Treningi" }}
                icon={SportsTennisIcon}
                name="trainings"
                {...trainings}
              />,
              <Resource
                options={{ label: "Stałe rezerwacje" }}
                icon={SportsTennisIcon}
                name="permanent-reservations"
                list={ReservationsList}
                edit={ReservationEdit}
                create={ReservationCreate}
              />,
              <Resource
                options={{ label: "Sekcje" }}
                icon={AccountTreeIcon}
                name="training-types"
                {...trainingTypes}
              />,
              <Resource
                options={{ label: "Rodzaje wyników" }}
                icon={TuneIcon}
                name="performance-types"
                list={PerformanceTypesList}
                edit={PerformanceTypeEdit}
                create={PerformanceTypeCreate}
              />,
            ]
          : [];
        const trainerOnly =
          isAdmin || isTrainer
            ? [
                <Resource
                  options={{ label: "Treningi" }}
                  icon={SportsTennisIcon}
                  name="trainings"
                  {...trainings}
                />,
              ]
            : [];
        const playerOnly = isPlayer
          ? [
              <Resource
                name="trainings"
                options={{ label: "Treningi" }}
                list={TrainingList}
              />,
              <Resource
                options={{ label: "Wyniki" }}
                icon={TimelineIcon}
                name="performance-results"
                list={PlayerPerformanceResultsList}
              />,
              <Resource
                options={{ label: "Ustawienia" }}
                icon={TimelineIcon}
                name="settings"
              />,
            ]
          : [];
        if (isAdmin) return adminOnly;
        if (isPlayer) return playerOnly;
        if (isTrainer) return trainerOnly;
      }}
    </Admin>
  );
};

export default App;
