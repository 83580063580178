import React from "react";
import {
  Edit,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate,
  NumberInput,
  DateTimeInput,
  BooleanInput,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { QueryFullNameField } from "./customs/CustomFields";
import { globalRefresh } from "../../helpers/globalRefresh";
import RichTextInput from "ra-input-rich-text";

const TrainingEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate("ra.action.cancel")}</Button>
  </Toolbar>
));

const TrainingEdit = ({ onCancel, ...props }) => (
  <Edit
    title=" "
    {...props}
    mutationMode="optimistic"
    onSuccess={() => {
      globalRefresh();
      onCancel();
    }}
  >
    <SimpleForm toolbar={<TrainingEditToolbar onCancel={onCancel} />}>
      <QueryFullNameField label="Zawodnik" />
      <NumberInput source="price" validate={required()} />
      <DateTimeInput source="date" label="Data i czas" validate={required()} />
      <BooleanInput source="didHappen" label="Trening zrealizowany" />
      <BooleanInput source="isPaid" label="Platność zrealizowana" />
      <RichTextInput
        source="notes"
        label="Notatki"
        toolbar={[["bold", "italic", "underline"]]}
      />
    </SimpleForm>
  </Edit>
);

export default TrainingEdit;
