// in src/users.js
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SimpleList,
} from "react-admin";
import { setTitle } from "../../../../api/title";

export const PerformanceTypesList = (props) => {
  setTitle("Rodzaje wyników");
  const displayDesktop = window.matchMedia("(min-width:1280px)").matches;
  return (
    <List title="Rodzaje wyników" {...props}>
      {displayDesktop ? (
        <Datagrid rowClick="edit">
          <TextField source="typeName" label="Nazwa" />
          <TextField source="unit" label="Jednostka" />
          <TextField source="id" label="ID" />
          <EditButton />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(record) => {
            // console.log({ record });
            return `${record.typeName}`;
          }}
          secondaryText={(record) => `Jednostka: ${record.unit}`}
          linkType="edit"
        />
      )}
    </List>
  );
};

export const PerformanceTypeEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="Identyfikator" />
        <TextInput source="typeName" label="Nazwa" />
        <TextInput source="unit" label="Jednostka" />
      </SimpleForm>
    </Edit>
  );
};

export const PerformanceTypeCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="typeName" label="Nazwa" />
        <TextInput source="unit" label="Jednostka" />
      </SimpleForm>
    </Create>
  );
};
