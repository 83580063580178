// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { DashboardShortcut } from "../DashboardShortcut/DashboardShortcut";
import { TrendingUp, Activity } from "react-feather";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Card>
    <CardHeader title="Dzień dobry!" />
    <CardContent>
      <div class="dashboardGrid">
        <DashboardShortcut
          icon={<Activity />}
          href="/#/trainings"
          label="Treningi"
        />
        <DashboardShortcut
          icon={<TrendingUp />}
          href="/#/performance-results"
          label="Wyniki"
        />
      </div>
    </CardContent>
  </Card>
);
