import { Query, Loading, Error } from "react-admin";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("pl");

export const FullNameField = ({ record = {} }) => {
  const fullName = `${record?.player?.name} ${record?.player?.surname}`;
  return <span>{fullName ? fullName : "Nieznany"}</span>;
};

export const PaymentField = ({ record = {} }) => {
  const isPaid = record?.isPaid;
  return isPaid ? "✅" : "❌";
};

export const TrainingHappenedField = ({ record = {} }) => {
  const didHappen = record?.didHappen;
  return didHappen ? "✅" : "❌";
};

export const TrainingDate = ({ record = {} }) => {
  const training = record?.date;
  if (!training) {
    return <span title="Nieprzypisany zadny trening">❓</span>;
  }
  return <span>{dayjs(training).format("DD-MM-YYYY, dddd HH:mm")}</span>;
};

export const QueryFullNameField = ({ record = {} }) => {
  return typeof record.player === "string" ? (
    <Query type="getOne" resource="users" payload={{ id: record.player }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Error />;
        }
        return (
          <div style={{ marginBottom: 20, marginTop: 10 }}>
            <strong>Zawodnik: </strong>
            <span>
              {data.name} {data.surname}
            </span>
          </div>
        );
      }}
    </Query>
  ) : (
    <FullNameField record={record} />
  );
};
